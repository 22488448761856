
   .service-block-container {
      display: flex;
      justify-content: center;
   }
   
   .service-block {
      min-width: 180px;
      max-width: 100%;
      width:100%;
      height: 250px;
      position: relative;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      display: flex;
      justify-content: center;
      text-align: center;
      background-color: #fff;
      color: #666;
      /* border: 1px solid #ccc; */
      margin-bottom: 30px;
      padding: 0 10px;
   }
   
   .service-underlay {
      position: absolute;
      height: 60px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #FBE8A2;
      padding-top: 18px;
      transition: all 0.3s ease-in-out;
   }
   
   .service-name {
      margin: 0 auto;
      display: block;
      color: #79611D;
      text-transform: uppercase;
   }
   
   .service-underlay .cta {
      margin-top: 30%;
      display: inline-block;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      transition: 0.3s ease-in-out;
   }
   
 
   
   .money-tips-list li{
      list-style-position: inside;
   }
   ul {
      list-style: none;
    }
    
    .money-tips-list li:before {
      /* content: '✓'; */
      content: url('https://img.icons8.com/emoji/20/000000/coin-emoji.png');
      margin-right: 5px;
      color:green;
      font-size: 20px;
    }

   @media only screen and (min-width: 768px) {
      .service-block:hover {
         transform: scale(1.1);
         cursor: pointer;
         /* border: 1px solid #79611D; */
         /* box-shadow: 0px 5px 3px #ccc; */
      }
      .service-block:hover .service-underlay {
         height: 250px;
         background-color: #79611D;
      }
   
      .service-block:hover .service-name {
         color: #fff;
         font-weight: bold;
      }
      
      
      .service-block .service-underlay .cta:hover {
         text-decoration: none;
         /* color: #000; */
         /* font-weight: bold; */
         font-size: 16px;
   
         text-transform: uppercase;
      }
   }