@keyframes jump {
    0% { top: 0; } 50% { top: 30px; } 100% { top: 0; }
  }
  
 
  #arrow-down {
    position: relative;
    margin: 0 auto;
    text-align: center;
    animation: jump 2s infinite;
    cursor: pointer;
  }

.carousel-header{
  font-size: 70px;
}

@media only screen and (max-width: 414px) {
  .carousel-smaller-header{
    font-size: 30px;
  }
  .carousel-header{
    font-size: 43px;
  }
}

@media only screen and (max-width: 414px) {
  .carousel-smaller-header{
    font-size: 30px;
  }
  .carousel-header{
    font-size: 43px;
  }
}

@media only screen and (width: 360px) {
  .carousel-smaller-header{
    font-size: 30px;
  }
  .carousel-header{
    font-size: 40px;
  }
}


/* NavBar Style */
.bottom-navigation-container {
  /* display: block!important;
    width: 100%!important;
    top: 100%!important;
    left: 0!important;
    max-width: none;
    background-color: #000; */
  /* border-top: 1px solid #2c2c2d; */
  z-index: 48;
  transition: 0.3s;
  /* opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); */
}

.navigation-container {
  transition: 0.3s;
}
a.navigation-link {
  position: relative;
  text-decoration: none !important;
  color: #fff;
  font-size: 12px;
}
a.navigation-link:hover {
  text-decoration: none !important;
  color: white;
}

a.navigation-link:before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  bottom: -10px;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  background-color: #969696;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.navigation-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: #fff;
}

/* NavBar style END */

/* Side Menu Style */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 65;
  left: 0;
  background-color: #000;
  overflow-x: hidden;
  transition: 0.3s;
  /* padding-top: 10px; */
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.mobile-menu-container {
  padding: 10px 30px 15px 20px;
  border-bottom: 1px solid #2c2c2d;
  transition: 0.3s;
}

.mobile-menu-main-container.hide {
  position: absolute;
  list-style: none;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(92, 91, 87, 0.9);
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}

.mobile-menu-main-container.show {
  display: block;
  visibility: visible;
  opacity: 1;
}
/* Side Menu Style End */
/* Boostrap Override */
.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

