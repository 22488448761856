

.myButton{
    padding:10px 40px;
    margin:10px 0px;
    font-size: 14px;
    font-weight:900;
    border: none !important;
    transition: 0.3s;
    text-transform: uppercase;
  }
  
  .myButton:disabled{
     background-color:#B2B2B2 ;
     cursor: not-allowed;
  }
  
  .myButton.small{
    padding:10px 10px;
    font-size: 12px;
  }
  .myButton:hover{
    opacity: 0.8;
  }
  
  .myButton.large{
    padding:15px 40px 10px 40px;
    font-size: 20px;
  }
  
  .primary{
    color:#000;
    background-color:#FBE8A2 ;
  }
  
  .secondary{
    color:#fff;
    background-color:#79611D ;
  }
  
  .dark{
    color:#79611D;
    background-color:#000 ;
  }