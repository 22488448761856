@font-face {
  font-family: Helvetic-main;
  src: url("./HelveticaNeueLTStd-Hv.woff");
}


@font-face {
  font-family: Helvetic-light;
  src: url("./HelveticaNeueLTStd-ThEx.woff");
}

@font-face {
  font-family: Lato;
  src: url("./Lato-Light.ttf");
}

.defaiutl-bold{
  font-family: Helvetic-main !important;
}

h1,h2,h3,h4,h5{
  font-family: 'Helvetic-main' !important;
  /* font-weight:900 !important; */
  text-transform: uppercase;
}
html{
  overflow-x: hidden !important;
  width:100% !important;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  /* font-family:'Open Sans', sans-serif !important; */
  font-weight: 400 !important;
  font-family:Lato, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  width:100% !important;
scroll-behavior: smooth;
}

video {
  object-fit: cover !important;
}

a:hover{
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:focus {
    outline:none !important
}
.form-control::-moz-placeholder{
    color:#999;
    opacity:1
}

.form-control:-ms-input-placeholder{
    color:#999
}

.form-control::-webkit-input-placeholder{
    color:#999
}

.bold{
    font-weight:900 !important;
}

button{
      font-family:Helvetic-main !important;
}



.slider-control-bottomcenter{
  display: none !important;
  visibility: hidden !important;
}

.menuButton {
    writing-mode: tb-rl;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(180deg);
    font-family:Helvetic-main;
    white-space: nowrap;
    display: block;
    top: 70px;
    right:12px;
    width: 20px;
    height: 20px;
    position: absolute;
    font-size: 18px;
    font-weight: bolder;
    color: #fff;
    cursor: pointer;
    pointer-events: all;
}

.link{
  cursor: pointer;
}

.menuLink{
    text-decoration: none;
    position: relative;
    margin-bottom: 30px;
    color: #fff;
    transition: 0.3s;
    font-size: 35px;
    font-weight:900;
    line-height: 1em;
    text-transform: uppercase;
}

.menuLink:hover{
  text-decoration: none;
}
.menuLink a{
    text-decoration: none;
    color: #fff;
    transition: 0.3s;

}
.menuLink a:hover{
  color:#FCF2D0;
  text-decoration: none;

}
.menuLink div{
    color: #fff;
    transition: 0.3s;
    cursor: pointer;
}
.menuLink div:hover{
  color:#FCF2D0;

}
.menuLink:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -10px;

    border-width: 0 0 2px;
    border-style: solid;
}



.form-control.search-input{
  font-size: 30px;
}

.search-input::placeholder { /* Firefox 18- */
  color: #fff !important;
  padding-left:20px;
  font-size: 30px;
}

.search-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
  padding-left:20px;
  font-size: 30px;
}
.search-input::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
  padding-left:20px;
  font-size: 30px;
}
.search-input:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
  padding-left:20px;
  font-size: 30px;
}
.search-input:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
  padding-left:20px;
  font-size: 30px;
}

.mapouter{position:relative;text-align:center;height:500px;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}





/* Pagination */
.pagination {
    justify-content: center;
    width: fit-content;
    text-align: center;
    padding:20px 30px 20px 30px !important;
    box-shadow:1px 1px 3px 1px rgba(33,160,210,0.35);
    align-self: center;
    text-align: center;
    align-content: center;
    border-radius: 0px !important;
}
.previous_page{
  padding:7.5px 30px;
  background: #21A0D2;
  color:#fff;
  cursor: pointer;
  font-size:14px;
  margin-right: 10px;
  display: none;
}

.next_page{
  padding:7.5px 30px;
  background: #21A0D2;
  color:#fff;
  cursor: pointer;
  font-size:14px;
  margin-right: 10px;
  margin-left: 10px;
  display: none;

}

.next_page:hover{
  color:#fff;
  font-size:14px;
}

.previous_page:hover{
  color:#fff;
  font-size:14px;
}

li.inactive_number a{
  padding:8px 14px;
  /* border:1px solid #ccc; */
  margin:0px 20px;
  color:#000 !important;
  font-weight: bold;
  margin-top:-5px;
  margin-left:0px;
  font-size:14px;
  cursor: pointer;
}

li.active_number a{
  color:#fff !important;
  background: #21A0D2;
  border:1px solid #21A0D2;
  font-weight: bold;

}

.pagination{
  margin-bottom: 30px;
}
.pagination li a{
  cursor: pointer;
  margin-top:-5px;
}
:focus{
  outline: none !important
}

.pagination-separator{
  font-size: 20px;
  margin-top: -5px;
}

/* END of Pagination */

@media only screen and (max-width: 600px) {
  .menuButton {
    top: 30px;
    font-size: 12px;
    text-transform: uppercase;
  }
  .menuLink{
    font-size: 40px;
    font-weight: normal;
    margin-bottom: 50px;
  }
  .carouselButton{
    margin-top: 270px !important;
    margin-right: 5px;
    margin-left: 5px;
  }
  
  /* .left-qoute{
    top:-10px;
    left:10px !important;
  }
  
  .right-qoute{
    bottom:2px !important;
    right:10px !important
  }
  
  .left-qoute-icon{
    font-size: 10px !important;
  }
  
  .right-qoute-icon{
    font-size: 10px !important;
  } */
  .quote-author{
    text-align: center !important;
  }
}
@media only screen and (max-width: 380px) {
  .pagination {
    width: 100%;
  }
}




.my-icons{
  transition: 0.3s ease-in-out;
}

.my-icons:hover{
  transform: scale(1.6);
  color:#79611D
}


.ohtech-link{
  color:#000;
  transition:0.3s ease-in-out;
  
}

.ohtech-link:hover{
  font-weight: bold;
  /* font-size: 13px; */
  color: #79611D !important;
}


.scroller-to-top{
  transition: 0.3s;
  cursor: pointer;
  position: fixed;
 
  right: 20px;
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  border: 1px solid rgb(0, 0, 0);
  background:#79611D; 
}

.scroller-to-top:hover{
  transform: scale(1.1);
}




/* Qoutes */


.carouselButton{
  height:40px; 
  width:40px;
  background-color:#79611D;
  display:flex;
  flex-direction:column;
  cursor: pointer;
}

.left-qoute{
  position: absolute;
  margin-right: 10px;
  top:-10px;
  left:-60px;
}

.right-qoute{
  position: absolute;
  margin-right: 10px;
  bottom: -10px;
  right:-60px
}

.left-qoute-icon{
  font-size: 30px;
}

.right-qoute-icon{
  font-size: 30px;
}


p#textArea2{
  color:#fff !important
}

.text-yellow{
  color: #000 !important;
}